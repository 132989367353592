<template>
  <div class="home d-flex flex-column align-center fill-height">
    <v-card width="80%" class="mt-4 mb-10">
      <v-img src="@/assets/whoweare.png"></v-img>
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        WHO WE ARE
      </v-card-title>
      <v-divider class="mb-5" />
      <v-card-text>
        <p class="font-weight-bold text-lg-h4 blue--text">
          We're All About Jesus, Period.
        </p>
        <p class="text-lg-subtitle-1">
          We exist to make the gospel known - but of course we have a little fun
          along the way! BibleStudyTwo is a group of young men and women who get
          together to learn about who God is, what He has done, and what He
          wants for us. And as the opportunity arises, we'll celebrate, play,
          serve, or even dress up in silly costumes.
        </p>
        <div class="d-flex flex-column flex-md-row">
          <v-hover v-slot:default="{ hover }">
            <div class="flex-fill">
              <v-card flat to="/about#high-school-boys">
                <v-card-title
                  class="
                    d-flex
                    justify-center
                    blue-grey--text
                    font-weight-bold
                    text-lg-h4 text-decoration-underline
                  "
                >
                  BibleStudyBoys
                </v-card-title>
                <v-card-text style="position: relative">
                  <v-img
                    src="@/assets/Dove-in-Circle.svg"
                    height="20rem"
                    contain
                  />
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        infoCard
                        blue-grey
                        fill-height
                        d-flex
                        flex-column
                        justify-space-around
                        text-md-h4 text-subtitle-1
                        white--text
                        text-center
                      "
                    >
                      <p>Middle School Boys meet on Tuesdays at 3PM</p>
                      <p>
                        High School Boys meet on Thursdays with dinner at 7PM
                      </p>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>

          <v-hover v-slot:default="{ hover }">
            <div class="flex-fill">
              <v-card flat to="/about#high-school-girls">
                <v-card-title
                  class="
                    d-flex
                    justify-center
                    blue-grey--text
                    font-weight-bold
                    text-lg-h4 text-decoration-underline
                  "
                >
                  BibleStudyGirls
                </v-card-title>
                <v-card-text style="position: relative">
                  <v-img src="@/assets/BSG.svg" height="20rem" contain />
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        infoCard
                        blue-grey
                        fill-height
                        d-flex
                        flex-column
                        justify-space-around
                        text-md-h4 text-subtitle-1
                        white--text
                        text-center
                      "
                    >
                      <p>
                        Middle School and High School Girls meet on Tuesdays at
                        6:00PM
                      </p>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-10" width="80%">
      <v-card-text>
        <div id="mni-membership-638620162996391441"></div>
      </v-card-text>
    </v-card>

    <v-card class="mb-16" width="80%">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        FAQ
      </v-card-title>
      <v-divider class="mb-5" />
      <v-expansion-panels class="mt-4" focusable>
        <!-- Where are you located -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Where Are You Located?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              All regular activities are at our Charity Woods property, located
              in the Orebank neighborhood of Kingsport at 217 Stage Rd.
            </div>
            <div class="mt-2">
              <strong>From Kingsport:</strong> Turn onto Orebank Rd. from East
              Stone Dr. (2.2 miles beyond the stoplight at the Kingsport
              Pavilion Shopping Center). Go 0.4 miles up Orebank Rd and turn
              left onto Rock City Rd. Take the first right (Stage Rd.) and go
              about a quarter mile to Charity Woods (on your left). Use the
              lower gate for BSG, upper gate for boys.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- How is bs2 supported -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            How is Bible Study Two Supported?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <strong>First,</strong> we're supported by a lot of great guys and
              girls who continually invite their friends and get new people
              involved in Bible Study every week.
            </div>
            <div class="mt-2">
              <strong>Second,</strong> we're supported by many great parents and
              friends who operate "in the background" via prayer and
              encouragement to the members.
            </div>
            <div class="mt-2">
              Financially we're supported in many ways. Sunday School classes
              and other church groups donate money or food. Several individuals
              do the same thing. If you're interested in checking out how you
              can help donate food check out our Get Involved page
              <a href="#/get-involved#food">here</a>. If you're interested in
              donating financially you can check out that out
              <a href="#/get-involved#monetary">here</a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- how can i help -->
        <v-expansion-panel>
          <v-expansion-panel-header> How Can I Help? </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              Check out how to <a href="#/get-involved">Get Involved</a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- connected w/ church -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Is BibleStudyTwo Connected With a Church?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              Not directly. The idea for BibleStudyTwo grew out of a part of the
              youth and missions ministry at Calvary Baptist Church, but it it
              not a direct part of Calvary. Bible Study participants come from a
              lot of different churches, and there are also many guys for whom
              Bible Study is their only conneciton with a Christian group.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- What goes on -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            What Goes on at Bible Study?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              For a weekly schedule you can check out
              <a href="#/about#schedule">here</a>
            </div>
            <div class="mt-2">
              For an overview of what is import to us you can check out
              <a href="#/about#whatsimportant">here</a>
            </div>
            <div class="mt-2">
              For more information on the schedules for different groups you can
              check out
              <a href="#/about#biblestudytwo">High School Boys</a>,
              <a href="#/about#biblestudymiddle">Middle School Boys</a>, and the
              <a href="#/about#biblestudygirls">Girls</a> group information.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Who Leads BibleStudyTwo -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Who Leads BibleStudyTwo?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              Bob Seymour, Cole Maupin and our Board of Directors provide
              overall leadership for the organization. Cole is responsible for
              leadership in boys’ groups. Lesa Gardner is responsible for
              leadership in the girls’ group.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- What else -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            What Else Does Bible Study Do?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              We sponser a child, Hahirwabasenga Joel, in Rwanda through
              Compassion International. The Bible Study group comes up with $32
              in donations every month to provide for him and his family.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- learn more -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Where Can I Learn More?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              You can learn about
              <ul>
                <li>
                  <a href="#/about#whatsimportant"> What's Important to Us </a>
                </li>
                <li><a href="#/about#history">Our History</a></li>
              </ul>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    // eslint-disable-next-line
    new MNI.Widgets.Member("mni-membership-638620162996391441", {
      member: 10085,
      styleTemplate:
        "#@id{text-align:center;position:relative}#@id .mn-widget-member-name{font-weight:700}#@id .mn-widget-member-logo{max-width:100%}",
    }).create();
  },
};
</script>

<style scoped>
.home {
  color: red;
}

.infoCard {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
}
</style>
